<template>
   <div class="col-12 px-0 mb-1" ref="afiliado">
      <div class="card mb-0" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col-md text-center text-md-start cursor-pointer" @click="visualizar">
                  <div class="row align-items-center">
                     <div class="col-12">
                        <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ afiliado.nomeFantasia }}</h1>
                        <p class="font-10 mb-0 mb-lg-2 limitador-1 text-secondary">
                           <span><i class="far fa-hashtag font-9 me-1"></i>{{ afiliado.id }}</span>
                           <span class="ms-3" v-if="afiliado.geral == false">
                              <i class="far fa-info-circle font-9 me-1"></i><span>Outro relatório já gerado anteriormente</span>
                           </span>
                           <span class="ms-3" v-else-if="afiliado.nomeLojaConvenio || afiliado.numeroLojaConvenio">
                              <i class="far fa-store font-9 me-1"></i>
                              <span>{{ afiliado.nomeLojaConvenio == null || String(afiliado.nomeLojaConvenio).trim().length == 0 ? 'Sem loja' : afiliado.nomeLojaConvenio }}</span>
                              <small class="mx-1"> | </small>
                              <i class="far fa-tag font-9 me-1"></i>
                              <span>{{ afiliado.numeroLojaConvenio == null || String(afiliado.numeroLojaConvenio).trim().length == 0 ? 'Sem número' : afiliado.numeroLojaConvenio }}</span>
                           </span>
                        </p>
                     </div>
                     <div class="col d-none d-lg-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-address-card color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> CPF/CNPJ:</strong>
                           <span> {{ afiliado.cpfCnpj == null ? 'Sem CPF' : afiliado.cpfCnpj }}</span>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="wpx-175 px-2 text-center mx-auto mx-lg-0 mt-2 mt-lg-0 font-15">
                  <div class="progress shadow-none mb-2">
                     <div class="progress-bar progress-bar-striped bg-theme" role="progressbar" :style="'width:'+ parseFloat(afiliado.valorUtilizado) / parseFloat(afiliado.valor) * 100 +'%'"></div>
                  </div>
                  <small class="d-block lh-sm font-12">
                     <span><small>R$ </small>{{ parseFloat(afiliado.valorUtilizado).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
                     <small class="mx-1"> | </small>
                     <span><small>R$ </small>{{ parseFloat(afiliado.valor).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
                  </small>
                  <small class="d-block lh-sm font-12"><i class="far fa-money-bill color-theme font-10 me-1"></i> Limite utilizado</small>
               </div>
               <div class="w-max-content d-flex align-items-center font-16 text-center mx-auto mx-md-0 mt-2 mt-md-0" v-if="!resumido">
                  <div class="btn-icone color-theme" v-if="dadosUsuario.usuarioPermissoes.includes('Convênios | Gerar relatórios')">
                     <span @click="gerarRelatorio"><i class="far fa-file-search"></i><small>Gerar relatório</small></span>
                  </div>
                  <div class="btn-icone color-theme">
                     <span @click="visualizar"><i class="far fa-receipt"></i><small>Comprovantes</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import router from '@/router'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
   name: 'Afiliado',
   props: ['afiliado', 'index', 'resumido'],
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest,
         tabs: state => state.tabs
      })
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      visualizar : function () {
         this.$emit('visualizar', this.afiliado)
      },
      gerarRelatorio : function () {
         Swal.fire({
            icon: 'warning',
            title: 'Gerar relatórios dos gastos?',
            text: 'Isso irá restaurar o valor utilizado do afiliado.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'convenios/gerarRelatorioUsuario',
                  params: {
                     idUsuario: this.afiliado.id
                  }
               }).then((response) => {
                  this.afiliado.valorUtilizado = "0.00"

                  Swal.fire({
                     icon: 'success',
                     title: 'Relatório gerado!',
                     text: 'Valor total: R$'+ parseFloat(response.data).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
                     showCancelButton: true,
                     confirmButtonText: 'Ir para relatórios',
                     cancelButtonText: `Fechar`
                     
                  }).then((result) => {
                     if (result.isConfirmed) {
                        router.push('/relatorios/'+ this.tabs.length)
                     }
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
      }
   }
}

</script>