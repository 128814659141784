<template>
   <div class="col-12 px-0 mb-1" ref="relatorio">
      <div class="card mb-0" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col-md text-center text-md-start cursor-pointer" @click="visualizar">
                  <div class="row align-items-center">
                     <div class="col-12">
                        <h1 class="font-15 mb-0 limitador-1">
                           <span>{{ relatorio.inicio == null ? 'Sem data' : relatorio.inicio }}</span>
                           <small class="mx-1"> ~ </small>
                           <span>{{ relatorio.fim == null ? 'Sem data' : relatorio.fim }}</span>
                        </h1>
                        <p class="font-10 mb-0 mb-lg-1 limitador-1 text-secondary"><i class="far fa-hashtag font-9 me-1"></i>{{ relatorio.id }}</p>
                     </div>
                     <div class="col-6 d-none d-xl-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-store color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Loja:</strong>
                           <span> {{ relatorio.nomeLoja == null ? 'Sem loja' : relatorio.nomeLoja }}</span>
                           <span v-if="relatorio.numeroLoja"><small class="mx-1"> | </small><span><i class="far fa-tag font-9 me-1"></i> {{ relatorio.numeroLoja }}</span></span>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="wpx-115 text-center mx-auto mx-lg-0 mt-2 mt-lg-0 font-15">
                  <span class="d-block lh-1"><small>R$ </small>{{ parseFloat(relatorio.valorTotal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
                  <small class="font-12"><i class="far fa-money-bill color-theme font-10 me-1"></i> Valor total</small>   
               </div>
               <div class="w-max-content d-flex align-items-center font-16 text-center mx-auto mx-md-0 mt-2 mt-md-0">
                  <div class="btn-icone color-theme">
                     <span @click="visualizar"><i class="far fa-print-search"></i><small>Visualizar</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Relatorio',
   props: ['relatorio', 'index'],
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      visualizar : function () {
         this.$emit('visualizar', this.relatorio)
      }
   }
}

</script>