<template>
	<div class="row">
		<div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Veja os relatórios de gastos gerados na tela de afiliados. Clique sobre um relatório para visualizá-lo.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row">
                  <div class="col-sm-6 col-lg-4 mb-3">
							<label><i class="far fa-store color-theme font-11 me-1"></i> Loja</label>
							<v-select name="Desktop" :options="dadosUsuario.usuarioLojas" v-model="pesquisa.loja" label="nomeFantasia" :reduce="loja => loja.id" :clearable="false" placeholder="Todas as lojas">
								<template #option="option">{{ option.nomeFantasia }}<small class="mx-2"><i class="fal fa-grip-lines-vertical"></i></small><i class="far fa-tag font-11 me-1"></i> {{ option.numeroLoja }}</template>
								<template v-slot:no-options>
									<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Solicite permissão ou utilize a tela "Lojas" para adicionar</small>
								</template>
							</v-select>
							<select name="Mobile" class="form-control" v-model="pesquisa.loja">
								<option v-for="(option, index) in dadosUsuario.usuarioLojas" :key="index" :value="option.id">{{ option.nomeFantasia }}&nbsp;&nbsp;|&nbsp;&nbsp;nº {{ option.numeroLoja }}</option>
							</select>
                  </div>
                  <div class="col-sm-6 col-lg-4 mb-3">
							<label><i class="far fa-user color-theme font-11 me-1"></i> Nome/CPF/RG</label>
                     <input type="text" class="form-control" v-model="pesquisa.nome" maxlength="200" v-focus @keyup.enter="buscarRelatorios" placeholder="Busque por nome do afiliado, CPF ou RG" />
                  </div>
						<div class="col-9 col-lg-3 mb-3">
							<label><i class="far fa-calendar color-theme font-11 me-1"></i> Data</label>
							<datePicker format="DD/MM/YYYY" :range="true" :clearable="false" :editable="false" v-model="pesquisa.data"></datePicker>
						</div>
						<div class="col-3 col-lg-1 px-0 mb-3 align-self-end">
							<button type="button" class="btn btn-primary w-100" @click="buscarRelatorios"><i class="fas fa-search font-13"></i></button>
						</div>
					</div>
            </div>
         </div>
      </div>

      <div class="col-12" v-if="pesquisa.retorno.resultado.length > 0">
			<div class="card mb-0">
				<div class="card-body p-12">
					<div class="row">
						<div class="col mb-2 align-self-center">
							<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisa.retorno.resultado.length == 100 ? 'Limitado a ': '' }} {{ pesquisa.retorno.resultado.length }} resultado(s)</div>
						</div>
						<div class="w-max-content mb-2">
							<a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="exportar"><i class="far fa-share font-10 me-1"></i> Exportar</a>
						</div>

						<relatorio v-for="(relatorio, index) in pesquisa.retorno.resultado" :key="index" :relatorio="relatorio" :index="index" @visualizar="buscarRelatorio($event)" />
					</div>
				</div>
			</div>
      </div>

      <!-- modalRelatorio -->
      <div class="modal fade" id="modalRelatorio" tabindex="-1" aria-labelledby="modalRelatorioLabel" aria-hidden="true">
         <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalRelatorioLabel">Relatório</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="row">
							<div class="col-6 px-0">
								<div class="card shadow-sm mb-1">
									<div class="card-body p-12">
										<h1 class="font-13 mb-1 limitador-1 text-uppercase"><i class="far fa-calendar color-theme font-10 me-1"></i> Data inicio</h1>
										<p class="font-13 mb-0 text-secondary text-end">
											<span class="limitador-1">{{ modal.inicio == null ? 'Sem data' : String(modal.inicio.dayOfMonth).padStart(2, '0') +'/'+ String(modal.inicio.monthValue).padStart(2, '0') +'/'+ String(modal.inicio.year) +' '+ String(modal.inicio.hour).padStart(2, '0') +':'+ String(modal.inicio.minute).padStart(2, '0') }}</span>
										</p>
									</div>
								</div>
							</div>
							<div class="col-6 pe-0">
								<div class="card shadow-sm mb-1">
									<div class="card-body p-12">
										<h1 class="font-13 mb-1 limitador-1 text-uppercase"><i class="far fa-calendar color-theme font-10 me-1"></i> Data fim</h1>
										<p class="font-13 mb-0 text-secondary text-end">
											<span class="limitador-1">{{ modal.fim == null ? 'Sem data' : String(modal.fim.dayOfMonth).padStart(2, '0') +'/'+ String(modal.fim.monthValue).padStart(2, '0') +'/'+ String(modal.fim.year) +' '+ String(modal.fim.hour).padStart(2, '0') +':'+ String(modal.fim.minute).padStart(2, '0') }}</span>
										</p>
									</div>
								</div>
							</div>
							<div class="col-6 px-0">
								<div class="card shadow-sm mb-1">
									<div class="card-body p-12">
										<h1 class="font-13 mb-1 limitador-1 text-uppercase"><i class="far fa-store color-theme font-10 me-1"></i> Loja</h1>
										<p class="font-13 mb-0 text-secondary text-end">
											<span class="limitador-1">{{ modal.nomeLoja == null ? 'Sem loja' : modal.nomeLoja }}<small class="mx-2">|</small>{{ modal.numeroLoja == null ? '?' : modal.numeroLoja }}</span>
										</p>
									</div>
								</div>
							</div>
							<div class="col-6 pe-0">
								<div class="card shadow-sm mb-1">
									<div class="card-body p-12">
										<h1 class="font-13 mb-1 limitador-1 text-uppercase"><i class="far fa-money-bill-wave-alt color-theme font-10 me-1"></i> Valor total</h1>
										<p class="font-13 mb-0 text-secondary text-end">
											<span class="limitador-1">{{ modal.valorTotal == null ? 'Sem valor' : 'R$ '+ parseFloat(modal.valorTotal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
										</p>
									</div>
								</div>
							</div>
						</div>

						<div class="card mt-1">
							<div class="card-body p-12">
								<div class="row">
									<div class="col-12 mb-3">
										<h6 class="mb-0 mt-1 font-15"><i class="far fa-users color-theme font-13 me-2"></i>Afiliados</h6>
									</div>

									<afiliado v-for="(afiliado, index) in modal.funcionarios" :key="index" :afiliado="afiliado" :index="index + 1" :resumido="true" />
									
									<div class="col-12 my-5 text-center" v-if="modal.funcionarios.length == 0">
										<i class="fal fa-users-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
										<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum afiliado encontrado</h4>
									</div>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
						<button type="button" class="btn btn-outline-primary" v-if="modal.id" @click="imprimir">Imprimir</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>

		<impressao id="impressao" class="d-none" :titulo="$route.name.replace(/_/g, ' ').replace(/-/g, '/')" :data="new Date()" :cabecalho="{
			'Período': modal.inicio == null && modal.fim == null ? ' - ' : 
				(modal.inicio == null ? 'Sem data' : (String(modal.inicio.dayOfMonth).padStart(2, '0') +'/'+ String(modal.inicio.monthValue).padStart(2, '0') +'/'+ modal.inicio.year))
				+' - '+ String(modal.fim.dayOfMonth).padStart(2, '0') +'/'+ String(modal.fim.monthValue).padStart(2, '0') +'/'+ modal.fim.year, 
			'Loja': modal.nomeLoja == null ? ' - ' : modal.nomeLoja,
			'Valor total': modal.valorTotal == null ? ' - ' : parseFloat(modal.valorTotal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2})
			}" :itensKey="{'nomeFantasia': 'Descrição', 'valorUtilizado': 'Utilizado', 'valor': 'Limite'}" :itens="modal.funcionarios" />
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import impressao from '@/components/impressao/ComprovanteA4.vue'
import afiliado from '@/components/convenios/afiliados/Afiliado.vue'
import relatorio from '@/components/convenios/relatorios/Relatorio.vue'

export default {
	name: 'Relatorios',
	data: function () {
		return {
			pesquisa: {'loja': null, 'convenioHabilitado': true, 'nome': '', 'data': [new Date(), new Date()], 'retorno': {'idLoja': null, 'numeroLoja': null, 'nome': null, 'resultado': []}},
			modal: {'id': null, 'inicio': null, 'fim': null, 'nomeLoja': null, 'numeroLoja': null, 'valorTotal': null, 'funcionarios': []}
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest,
			tabs: state => state.tabs
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
         Object.assign(this.$data, this.tabs[value].data)
      },
      'pesquisa.data' : function (value) {
         for (let i = 0; i < value.length; i++) {
            if (!(value[i] instanceof Date)) {
               value[i] = new Date(value[i])
            }
         }
      }
	},
	components: {
		relatorio, afiliado, impressao
	},
	methods: {
		imprimir : function () {
         setTimeout(() => this.$htmlToPaper('impressao', {styles: ['./comprovanteA4.css']}), 250);
      },
		exportar : function () {
			this.$store.dispatch('exportarExcel', {
				'nome': 'Relatórios',
				'resultado': this.pesquisa.retorno.resultado, 
				'campos': ['id', 'numeroLoja', 'nomeLoja', 'geral', 'inicio', 'fim', 'valorTotal'], 
				'lista': null
			})
		},
		buscarRelatorios : function () {
			if (Math.round((this.pesquisa.data[1].getTime() - this.pesquisa.data[0].getTime()) / (24 * 60 * 60 * 1000)) >= 90) {
				this.$toast.fire({
					icon: 'error',
					title: 'Período da busca maior que 90 dias.'
				});
	
				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.pesquisa.retorno, this.$options.data.apply(this).pesquisa.retorno)

			this.$axios({
				method: 'post',
				url: this.urlRest +'convenios/searchRelatorios',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa

			}).then(response => {
				this.pesquisa.retorno = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		buscarRelatorio : function (relatorio) {
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.modal, this.$options.data.apply(this).modal)

			$('#modalRelatorio').modal('show')

			this.$axios({
				method: 'get',
				url: this.urlRest +'convenios/getRelatorio',
				params: {
					id: relatorio.id
				}
			}).then(response => {
				this.modal = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		}
	},
	mounted() {
		Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)
	}
}

</script>