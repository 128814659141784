<template>
   <div>
      <div id="body">
         <div class="invoice-a4">
            <header class="bleed">
               <div class="header-grid">
                  <div class="stack stack--loose">
                     <h1>{{ titulo }}</h1>
                     <div class="stack stack--xtight">
                        <p><span class="text-semibold">Data gerado:</span> {{ !(data instanceof Date) ? data : data.getDate().toString().padStart(2, "0") +'/'+ (data.getMonth() + 1).toString().padStart(2, "0") +'/'+ data.getFullYear() +' '+ data.getHours().toString().padStart(2, "0") +':'+ data.getMinutes().toString().padStart(2, "0") }}</p>
                     </div>
                  </div>
                  <div class="logo-section">
                     <img class="logo" :src="imgLogo" />
                  </div>
               </div>
            </header>
            <main>
               <div class="stack stack--tight" v-if="cabecalho">
                  <div class="stack stack--xtight">
                     <h2 class="text-semibold">Informações complementares</h2>
                     <p v-for="(value, key) in cabecalho" :key="key">{{ key }}: {{ value }}</p>
                  </div>
               </div>
               <div class="tables stack stack--loose">
                  <div class="items-section">
                     <table class="table items-table" aria-label="Line items">
                        <thead>
                           <tr class="table-row">
                              <th class="table-cell text-semibold" v-for="(value, key, index) in itensKey" :key="index" :class="index == 0 ? '' : 'table-cell--numeric'">{{ value }}</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr class="table-row" v-for="(item, index) in itens" :key="index">
                              <td class="table-cell" v-for="(value, key, index) in itensKey" :key="key" :class="index == 0 ? '' : 'table-cell--numeric'">
                                 {{ item[key] == null ? '-' : key.includes('qtd') || key.includes('quantidade') || key.includes('unitario') || key.includes('valor') || key.includes('preco') ? parseFloat(item[key]).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:4}) : item[key] }}
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
                  <div class="items-section" v-if="itens2 && itens2Key">
                     <table class="table items-table" aria-label="Line items">
                        <thead>
                           <tr class="table-row">
                              <th class="table-cell text-semibold" v-for="(value, key, index) in itens2Key" :key="index" :class="index == 0 ? '' : 'table-cell--numeric'">{{ value }}</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr class="table-row" v-for="(item, index) in itens2" :key="index">
                              <td class="table-cell" v-for="(value, key, index) in itens2Key" :key="key" :class="index == 0 ? '' : 'table-cell--numeric'">
                                 {{ item[key] == null ? '-' : key.includes('qtd') || key.includes('quantidade') || key.includes('unitario') || key.includes('valor') || key.includes('preco') ? parseFloat(item[key]).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:4}) : item[key] }}
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
                  <div class="totals-section" v-if="total">
                     <div class="totals-header stack stack--tight"></div>
                     <div class="totals-table">
                        <table class="table" aria-label="Totals">
                           <tbody>
                              <tr class="table-row">
                                 <th class="table-cell text-semibold">Total</th>
                                 <td class="table-cell table-cell--numeric">R$ {{ parseFloat(total).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </main>
         </div>
      </div>
   </div>
</template>

<script>

import logo from '@/assets/images/logo-icon.png'

export default {
   name: 'ComprovanteA4',
   props: ['titulo', 'data', 'cabecalho', 'itensKey', 'itens', 'itens2Key', 'itens2', 'total'],
   data: function () {
      return {
         imgLogo: logo
      }
   }
}

</script>

<style scoped>

@import '/comprovanteA4.css';

</style>